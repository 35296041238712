import React, { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import routes from "./routes";
import Layout from "./layouts/Layout";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { AuthProvider } from "./contexts/AuthProvider";
import NavBar from "./layouts/main/NavBar";
import WebsitePage from "./pages/WebsitePage";
import Loader from "./components/Loader";
import AboutPage from "./pages/AboutPage";
import Login from "./layouts/Login";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"; // Import axios for making API calls
import { baseUrl } from "./config/url";
import BlogPage from "./pages/BlogPage";
import ReactGA from 'react-ga4';
import VideoPage from "./pages/VideoPage";
import NotFoundPage from "./pages/NotFoundPage"; // Import NotFoundPage

const App = () => {
  const location = useLocation(); 

  useEffect(() => {
    const callViewAPI = async () => {
      console.log("Calling View");
      let routePath = location.pathname.substring(1);
      if (routePath.length === 0) {
        routePath = "home";
      }
      try {
        await axios.get(`${baseUrl}views/${routePath}`);
      } catch (error) {
        console.error("Error calling view API:", error);
      }
    };

    callViewAPI();
  }, [location]);

  return (
    <AuthProvider>
      <ToastContainer />
      <Provider store={store}>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route element={<Layout />}>
              {routes.map((route, key) => (
                <Route key={key} exact={true} path={`${route.path}`} element={<route.component />} />
              ))}
            </Route>
            <Route exact={true} path="/about" element={<AboutPage />} />
            <Route exact={true} path="/:domain" element={<WebsitePage />} />
            <Route exact={true} path="/blogs/:blogLink" element={<BlogPage />} />
            <Route exact={true} path="/videos/:videoId" element={<VideoPage />} />
            {/* 404 Route */}
            <Route path="/404" element={<NotFoundPage />} />
          </Routes>
          <Login />
        </Suspense>
      </Provider>
    </AuthProvider>
  );
};

export default App;
